html,
body {
    font-family: 'NotoSans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
        Droid Sans, Helvetica Neue, sans-serif;
    position: relative;
    scroll-behavior: smooth;
}

.fixBody {
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

img {
    vertical-align: middle;
}

/* md */
@media screen and (max-width: 1199px) {
    html {
        font-size: 16px;
    }
}

/* sm */
@media screen and (max-width: 899px) {
    html {
        font-size: 14px;
    }
}

/* xs */
@media screen and (max-width: 599px) {
    html {
        font-size: 12px;
    }
}

@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Noto Sans'), local('NotoSans'), url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Medium.eot'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Medium.woff') format('woff'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Medium.woff2') format('woff2'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Medium.otf') format('truetype');
}

@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Noto Sans Bold'), local('NotoSansBold'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Bold.eot'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Bold.woff') format('woff'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Bold.woff2') format('woff2'),
        url('https://meta.sodportal.io/hp/fonts/NotoSans/NotoSans-Bold.otf') format('truetype');
}
